
<template>
  <div>
    <div class="header-title">{{ $route.meta.title }}</div>
    <div class="">
      <div>
        <div class="btn-con" style="float: left">
          <button
            type="button"
            class="next-btn next-medium next-btn-primary"
            style="margin-right: 8px"
            @click="adduser"
          >
            <span class="next-btn-helper">创建角色</span>
          </button>
        </div>
        <el-input
          v-model="page.search"
          prefix-icon="el-icon-search"
          size="small"
          style="width: 300px"
          placeholder="输入角色名"
          clearable
          @keyup.enter.native="get_list('reset')"
          @clear="get_list('reset')"
        />
      </div>
      <Loading :show="loading">
        <div class="list-box">
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="headerCallStyle"
            @selection-change="handleCheckedCitiesChange"
          >
            <el-table-column type="selection" align="center" width="55" />
            <el-table-column prop="name" label="角色名称">
              <template slot-scope="{row}">
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="handle(row)"
                >
                  {{ row.name }}
                </button>
              </template>
            </el-table-column>
            <el-table-column prop="domain_name" label="所属域" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column prop="create_time" label="创建时间" />
            <el-table-column label="操作">
              <template slot-scope="{row}">
                <!-- <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                >
                  <span class="next-btn-helper">添加组成员</span></button
                ><button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                >
                  <span class="next-btn-helper">添加权限</span></button
                > -->
                <button
                  type="button"
                  class="next-btn next-medium next-btn-primary next-btn-text"
                  style="margin-right: 8px"
                  @click="delete_role(row)"
                >
                  <span class="next-btn-helper">删除</span>
                </button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="padding: 20px 10px 10px; font-size: 18px">
          <div style="text-align: right">
            <el-pagination
              :page-size="page.pageSize"
              :current-page="page.pageNum"
              background
              layout="total, prev, pager, next"
              :total="total"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </Loading>
    </div>
    <el-dialog
      title="请选择域"
      :visible.sync="dialogVisible"
      width="350px">
      <el-select v-model="domain_id" placeholder="请选择">
        <el-option
          v-for="item in domain_list"
          :key="item.domain_id"
          :label="item.domain_name"
          :value="item.domain_id" />
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="assingFun">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { roles_list, rem_role, domain_assing } from '@/api/roles'
import { domain_list } from '@/api'

export default {
  data () {
    return {
      ids: [],
      total: 0,
      dialogVisible: false,
      tableData: [],
      domain_id: '',
      domain_list: [],
      loading: false,
      checkAll: false,
      disabledAll: false,
      isIndeterminate: true,
      page: { search: '', pageNum: 1, pageSize: 10 }
    }
  },
  computed: {
    headerCallStyle () {
      return {
        background: '#eff3f8',
        color: '#333',
        fontWeight: 400,
        fontSize: '12px'
      }
    }
  },
  created () {
    this.get_list()
    this.get_domain_All()
  },
  methods: {
    async get_domain_All () {
      const { data } = await domain_list()
      this.domain_list = data
    },
    assingFun () {
      const postData = {
        role_id_list: this.ids,
        domain_id: this.domain_id
      }
      if (this.domain_id && this.domain_id > -1) {
        domain_assing(postData).then(res => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
          this.dialogVisible = false
          this.domain_id = ''
          this.get_list()
        }).catch(() => {
          this.dialogVisible = false
          this.domain_id = ''
          this.get_list()
        })
      } else {
        alert('请先选择域！')
      }
    },

    handleCheckedCitiesChange (list) {
      const checkedCount = list.length
      this.disabledAll = checkedCount
      this.checkAll = checkedCount === this.tableData.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length
      this.ids = list.map(({ id }) => id)
    },
    async get_list (reset) {
      if (reset == 'reset') {
        this.page.pageNum = 1
      }
      try {
        this.loading = true
        const params = this.page
        const data = await roles_list(params)
        this.loading = false
        this.tableData = data.data
        this.total = data.count
      } catch (error) {
        this.loading = false
      }
    },

    adduser () {
      this.$router.push('/roles/add')
    },
    handle ({ id, domain_id }) {
      this.$router.push({
        path: '/roles/handle',
        query: { id, domain_id }
      })
    },
    delete_role (row) {
      const params = {
        role_id: row.id,
        domain_id: this.$store.state.userInfo.domain_id
      }

      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rem_role(params).then(res => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'success'
            })
          }
          this.page.pageNum = 1
          // const totalPage = Math.ceil((this.total - 1) / this.page.pageSize)
          // const currengpage = this.page.pageNum > totalPage ? totalPage : this.page.pageNum
          // this.page.pageNum = currengpage < 1 ? 1 : currengpage
          this.get_list()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleCurrentChange (val) {
      this.page.pageNum = val
      this.get_list()
    }
  }
}
</script>

<style>
</style>
